var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{attrs:{"fluid":""}},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"icon":"mdi-clipboard-text","title":"ข้อมูลสถานศึกษา"}},[_c('v-data-table',{attrs:{"color":"success","loading":_vm.loading,"headers":_vm.headers,"items":_vm.colleges,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mb-2",attrs:{"append-icon":"mdi-magnify","label":"ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง","single-line":"","hide-details":"","dense":"","filled":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.view",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"fab":"","small":"","color":"blue","to":("/pages/collegesid/?college_ID=" + (item.college_code))}},[_c('v-icon',[_vm._v(" mdi-magnify ")])],1)]}},{key:"item.collegeDev",fn:function(ref){
var item = ref.item;
return [(item.collegeDev === 'Dev')?_c('div',{staticClass:"text-center"},[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-checkbox-marked-circle-outline")]),_vm._v(" พัฒนา ")],1):_vm._e()]}},{key:"item.college_vecpro",fn:function(ref){
var item = ref.item;
return [(item.college_vecpro === '1')?_c('div',{staticClass:"text-center"},[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-checkbox-marked-circle-outline")]),_vm._v(" สอจ. ")],1):_vm._e()]}}])},[_c('v-alert',{attrs:{"slot":"no-results","value":true,"color":"error","icon":"mdi-alert"},slot:"no-results"},[_vm._v("ไม่พบผลลัพธ์ \""+_vm._s(_vm.search)+"\" ที่คุณกำลังค้นหา.")])],1)],1)],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-snackbar',{attrs:{"top":"","timeout":_vm.snackbar.timeout,"color":_vm.snackbar.color},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snackbar.show = false}}},'v-btn',attrs,false),[_vm._v("Close")])]}}]),model:{value:(_vm.snackbar.show),callback:function ($$v) {_vm.$set(_vm.snackbar, "show", $$v)},expression:"snackbar.show"}},[_c('v-icon',{attrs:{"large":""}},[_vm._v(_vm._s(_vm.snackbar.icon))]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.snackbar.text)+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }