<template>
  <div>
    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-text"
        title="ข้อมูลสถานศึกษา"
        class="px-5 py-3"
      >
        <v-data-table
          color="success"
          :loading="loading"
          :headers="headers"
          :items="colleges"
          :search="search"
        >
          <template v-slot:top>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
              single-line
              hide-details
              dense
              filled
              class="mb-2"
            />
          </template>
          <template v-slot:item.view="{ item }">
            <v-btn
              fab
              small
              color="blue"
              :to="`/pages/collegesid/?college_ID=${item.college_code}`"
            >
              <v-icon>
                mdi-magnify
              </v-icon>
            </v-btn>
          </template>
          <template v-slot:item.collegeDev="{ item }">
            <div v-if="item.collegeDev === 'Dev'" class="text-center">
              <v-icon color="green">mdi-checkbox-marked-circle-outline</v-icon>
              พัฒนา
            </div>
          </template>

          <template v-slot:item.college_vecpro="{ item }">
            <div v-if="item.college_vecpro === '1'" class="text-center">
              <v-icon color="green">mdi-checkbox-marked-circle-outline</v-icon>
              สอจ.
            </div>
          </template>

        
          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>
    </v-container>

    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <v-card-text>
          {{ snackbar.text }}
        </v-card-text>

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.show = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: true,
      ApiKey: "HRvec2021",
      valid: true,
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      colleges: [],

      search: "",
      pagination: {},
      headers: [
        { text: "View", align: "left", value: "view" },
        { text: "ชื่อสถานศึกษา", align: "left", value: "college_name" },
        { text: "ชื่อย่อ", align: "left", value: "collegeIni" },
        { text: "สถาบันการอาชีวศึกษา", align: "left", value: "veiVocalName" },
        { text: "อำเภอ", align: "left", value: "prefecture_name" },
        { text: "จังหวัด", align: "left", value: "province_name" },
        { text: "ประเภท", align: "left", value: "collegetype_name" },
        { text: "สถานศึกษาพัฒนา", align: "left", value: "collegeDev" },
        { text: "สอจ.", align: "left", value: "college_vecpro" }
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ]
    };
  },
  async mounted() {
    await this.collegeQueryAll();
  },

  methods: {
    async collegeQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("college.php", {
          ApiKey: this.ApiKey
        })
        .finally(() => (this.loading = false));
      this.colleges = result.data;
    }
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    }
  }
};
</script>
